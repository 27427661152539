<!-- RegionEdu 区域宣教 --> 
<template>
    <div>
        <isHeader />
        <isBanner :title="title" :desc="desc" :src="src"/>
        <isPro1 v-runshow/>
        <isPro2 v-runshow/>
        <isPro3 v-runshow/>
        <isFooter />
    </div>
</template>

<script>
    import isHeader from '@/components/header/index.vue';
    import isBanner from '@/components/Solution/isBanner.vue';
    import isPro1 from '@/components/RegionEdu/isPro1.vue';
    import isPro2 from '@/components/RegionEdu/isPro2.vue';
    import isPro3 from '@/components/RegionEdu/isPro3.vue';
    import isFooter from '@/components/footer/index.vue';
    import imgForBanner from '@/assets/Solution/regionEducation_banner.jpg';
    export default {
        name: 'RegionEdu',
        data: () => {
            return {
                title: '区域健康宣教',
                desc: '为患者提供端到端的健康宣教内容推送，协助区域医疗机构打造数据可追溯、闭环式智慧宣教管理平台',
                src: imgForBanner,
            }
        },
        components: {
            isHeader,
            isBanner,
            isPro1,
            isPro2,
            isPro3,
            isFooter,
        }
    }
</script>